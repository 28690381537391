import { useState } from "react";

import { VideoSection } from "components/VideoSection";

import "css/home.css";

const Home = () => {
  const [videoUrl, setVideoUrl] = useState(null);
  const [videoTitle, setVideoTitle] = useState(null);

  const VIDEOS = {
    COOPER_HURLEY: {
      featured: {
        label: ":30 Group Spot",
        thumbnail:
          "https://i.vimeocdn.com/video/1398929002-1411dff4397ab074339b4255254e00690e52c8aaa78bdcf518c30ccc111d2a29-d?mw=1300&mh=731",
        url: "https://player.vimeo.com/video/690963582?h=11eb5e009a",
      },
      grid: [
        {
          label: ":30 TJ Testimonial",
          thumbnail:
            "https://i.vimeocdn.com/video/1367997585-7ea4b63e8828750ad7c9a3d14c6106356a988b6fa6bfa814eaa276a171244077-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/674883028?h=c8ec495de9",
        },
        {
          label: ":30 Daniel Testimonial",
          thumbnail:
            "https://i.vimeocdn.com/video/1367995170-ce1a7e390fc974b16fa446cbf74bae3c34e6ce9bf57a9ab2d9000d44e503815c-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/674882295?h=082612b2d1",
        },
        {
          label: ":30 Rita Testimonial",
          thumbnail:
            "https://i.vimeocdn.com/video/1367995632-0eec8cb5765730aebb03c6bb29bb664fffb92c880bc96f5483d383e340d36687-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/674882666?h=939c26ec02",
        },
        {
          label: "TJ Longform Testimonial",
          thumbnail:
            "https://i.vimeocdn.com/video/1370026067-cb2916ae7b96558709c95493fa7f778db4e1090d786f601fc0a66a09b36af96e-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/676081650?h=a476d6e5ca",
        },
        {
          label: "Daniel Longform Testimonial",
          thumbnail:
            "https://i.vimeocdn.com/video/1370076895-5e0157553ab76a2c60d13980059051545bfbf96436a0d145633a61c73f01c084-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/676102921?h=f0e1d821ac",
        },
        {
          label: "Rita Longform Testimonial",
          thumbnail:
            "https://i.vimeocdn.com/video/1370010670-717a5d81cb627f23d40385861ff6d8928a574d9f8886ca081f9bd9bbbc74de57-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/676074794?h=58b4e1bf2b",
        },
      ],
    },
    MARTIN_JONES: {
      featured: {
        label: "Martin & Jones Brand Film",
        thumbnail:
          "https://i.vimeocdn.com/video/1398935786-0906f8a002c5a7b49777c7f76286449587363e2c716e59b28e1a027b05a397fa-d?mw=1300&mh=731",
        url: "https://player.vimeo.com/video/690968325?h=6bbf47afae",
      },
      grid: [
        {
          label: ":30 Traffic Accident",
          thumbnail:
            "https://i.vimeocdn.com/video/1398937331-047108b65d779dcba04ecc2159b515c5106ef7e34fd05d3d72d8acadf0a7be02-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/690969036?h=dead0d33a6",
        },
        {
          label: ":30 Tractor Trailer",
          thumbnail:
            "https://i.vimeocdn.com/video/1398938134-3a49ba46e982b2aec780e282ed8cc0812c04800c3972ef14872082c6f3810f4c-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/690969914?h=3a6339596c",
        },
        {
          label: ":30 Workers Comp",
          thumbnail:
            "https://i.vimeocdn.com/video/1398936385-04802245a3788215f3080a942816e8ca179535386335e5fe73fcc7e4e3612edf-d?mw=1300&mh=731",
          url: "https://player.vimeo.com/video/690968597?h=4f054088b0",
        },
        {
          label: ":15 Traffic Accident",
          thumbnail:
            "https://i.vimeocdn.com/video/1398937732-cfd88868284b9330cf69b4752a1e11f86cb79769b971f3c2ba9b8438bba6264d-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/690969374?h=8047612cfd",
        },
        {
          label: ":15 Tractor Trailer",
          thumbnail:
            "https://i.vimeocdn.com/video/1398937731-132a2b4b30c9c9358656ea7909befab225ffbd53e9c1347d4890c72f9fc7784c-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/690969519?h=246c2bb28b",
        },
        {
          label: ":15 Workers Comp",
          thumbnail:
            "https://i.vimeocdn.com/video/1398936462-d65a799927a76492806513dd18b0c9da1fc9c705dc4d8e00d35df8e8c0103944-d?mw=1700&mh=956&q=70",
          url: "https://player.vimeo.com/video/690968891?h=25b75981a3",
        },
      ],
    },
  };

  const VIDEOS_ALL = [
    // {
    //   label: "Davis Ad Agency",
    //   url: "https://player.vimeo.com/video/691139955?h=48f767018f",
    //   thumbnail:
    //     "https://i.vimeocdn.com/video/1399203936-eb825758be6525039ebd5337794f21e6b3efe08d20ae77f6b8e9f8c1fd92d3df-d?mw=2500&mh=1406&q=70",
    // },
    VIDEOS.MARTIN_JONES.featured,
    ...VIDEOS.MARTIN_JONES.grid,
    VIDEOS.COOPER_HURLEY.featured,
    ...VIDEOS.COOPER_HURLEY.grid,
  ];

  const closeLightbox = () => {
    document.querySelector("body").classList.remove("overflow-hidden");
    setVideoUrl(null);
  };

  const setVideoNext = () => {
    const videoIndexCurrent = VIDEOS_ALL.findIndex((video) => {
      return video.url === videoUrl;
    });

    const videoIndexNext =
      videoIndexCurrent === VIDEOS_ALL.length - 1 ? 0 : videoIndexCurrent + 1;

    setVideoTitle(VIDEOS_ALL[videoIndexNext].label);
    setVideoUrl(VIDEOS_ALL[videoIndexNext].url);
  };

  const setVideoPrevious = () => {
    const videoIndexCurrent = VIDEOS_ALL.findIndex((video) => {
      return video.url === videoUrl;
    });

    const videoIndexPrevious =
      videoIndexCurrent === 0 ? VIDEOS_ALL.length - 1 : videoIndexCurrent - 1;

    setVideoTitle(VIDEOS_ALL[videoIndexPrevious].label);
    setVideoUrl(VIDEOS_ALL[videoIndexPrevious].url);
  };

  const openLightbox = (video) => {
    const lightbox = document.querySelector(".lightbox");
    const scrollOffset =
      window.pageYOffset || document.documentElement.scrollTop;

    document.querySelector("body").classList.add("overflow-hidden");
    setVideoTitle(video.label);
    setVideoUrl(video.url);

    if (lightbox) {
      lightbox.style.top = `${scrollOffset}px`;
    }
  };

  return (
    <>
      <header className="flex justify-between items-center mb-4">
        <div
          alt="Davis Ad Agency"
          className="mr-1"
          style={{ width: 100, height: 100, margin: "auto" }}
        >
          <svg
            class="logo"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 171.2 171.2"
          >
            <path fill="#df1e36" d="M0 0v171.2h171.2V0H0"></path>
            <path
              d="M18.8 135h9.3c7.9 0 12.3-4.1 12.3-12.8v-21.8c0-7.5-4.2-12-11.7-12h-9.9V135zm6.6-40.4h3.1c3.9 0 5.2 2.1 5.2 5.8v22.5c0 4-1.4 5.8-5.2 5.8h-3.1V94.6zm39.2 24h-6.7l3.3-16.9h.1l3.3 16.9zm-16.5 16.3h6.7l2-10h9.1l2 10h6.7L64.1 88.3h-5.6l-10.4 46.6zm55.1-46.6h-7.1l-5.4 32.5h-.2l-5.3-32.5h-7.1l9.5 46.6h5.9l9.7-46.6zm9.4 46.7h6.7V88.4h-6.7V135zm40-36.5A10.82 10.82 0 0 0 141.5 88c-7.7 0-11.5 5.7-11.5 12.8 0 5.8 1.5 9.6 7.3 12l4.8 2c3.3 1.3 3.9 2.1 3.9 7.1 0 4.2-.3 6.9-4.8 6.9-3.4 0-4.7-2.4-4.7-4.8v-2.1h-6.7v2.5a11.1 11.1 0 0 0 10.9 11.1h.5c8.6 0 11.5-5.8 11.5-13.7 0-6.8-1.1-10.5-7-12.8l-5.1-2c-3.5-1.4-3.8-2.6-3.8-6.2 0-3.1.9-6 4.5-6 3.4 0 4.7 2.5 4.7 5.6v1.5h6.7v-3.4zM60.6 141.5h2.1l4 17.9h-2.6l-.8-3.9h-3.5l-.8 3.9h-2.5zm1.1 5.1h-.1l-1.3 6.5h2.6zM69.1 141.5h3.8c2.9 0 4.5 1.7 4.5 4.6v8.4c0 3.3-1.7 4.9-4.7 4.9h-3.6v-17.9zm2.6 15.5h1.2c1.4 0 2-.7 2-2.2v-8.6c0-1.4-.5-2.2-2-2.2h-1.2zM88.6 141.5h2.1l4 17.9h-2.6l-.8-3.9h-3.5l-.8 3.9h-2.5zm1.1 5.1l-1.3 6.5H91zM97 145.7a4.3 4.3 0 0 1 8.6 0v.9H103v-.9a1.76 1.76 0 1 0-3.5-.3v9.8a1.76 1.76 0 1 0 3.5.3v-3.6h-2v-2.3h4.5v5.6a4.3 4.3 0 1 1-8.6 0l.1-9.5zM109 141.5h7.6v2.4h-5.1v5.3h4.4v2.4h-4.4v5.3h5.1v2.6H109v-18zM119.4 141.5h2.5l3.9 10.8v-10.8h2.6v17.9H126l-3.9-10.8v10.8h-2.6l-.1-17.9zM139.9 155.4a4.06 4.06 0 0 1-4.1 4.1 3.86 3.86 0 0 1-4.2-4.2v-9.9a4 4 0 0 1 3.9-4.1h.3a4.1 4.1 0 0 1 4.1 4v1.3h-2.6v-.9a1.77 1.77 0 0 0-1.5-1.9h-.1c-1.3 0-1.6.9-1.6 2.1v9.2c0 1 .4 1.8 1.6 1.8a1.56 1.56 0 0 0 1.6-1.5v-1.2h2.6v1.2zM145.5 151.8l-3.5-10.3h2.7l2.1 7.1 2.1-7.1h2.8l-3.5 10.3v7.6h-2.6l-.1-7.6z"
              fill="#fff"
            ></path>
          </svg>
        </div>
        <div
          className="flex mr-1"
          style={{
            alignItems: "center",
            position: "absolute",
            right: 10,
          }}
        >
          <a
            href="mailto:inquiries@davisadagency.com"
            rel="noreferrer"
            target="_blank"
          >
            <svg
              width="22"
              height="15"
              viewBox="0 0 22 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mr-1"
            >
              <g clip-path="url(#clip0_3_178)">
                <path
                  d="M20.347 15.0006H1.65253C1.21434 15 0.794239 14.8153 0.484411 14.4868C0.174584 14.1583 0.000330572 13.7129 -0.00012207 13.2484V1.75203C0.000330687 1.28753 0.174601 0.8422 0.484435 0.513753C0.794269 0.185305 1.21436 0.00057151 1.65253 9.15527e-05H20.347C20.7852 0.000571388 21.2053 0.185293 21.5152 0.513734C21.8251 0.842174 21.9994 1.28751 21.9999 1.75203V13.2479C21.9995 13.7125 21.8253 14.158 21.5154 14.4866C21.2055 14.8152 20.7853 15 20.347 15.0006ZM1.65253 1.16692C1.50612 1.1672 1.36578 1.229 1.26227 1.33877C1.15877 1.44854 1.10054 1.59734 1.10034 1.75254V13.2484C1.10047 13.4036 1.15868 13.5525 1.2622 13.6622C1.36572 13.772 1.5061 13.8338 1.65253 13.834H20.347C20.4935 13.8339 20.6339 13.7721 20.7375 13.6623C20.8411 13.5525 20.8993 13.4037 20.8994 13.2484V1.75203C20.8992 1.59677 20.841 1.44794 20.7374 1.33815C20.6339 1.22837 20.4935 1.16661 20.347 1.1664L1.65253 1.16692Z"
                  fill="#DF1E36"
                />
                <path
                  d="M13.6799 6.55154L13.2427 7.07593L20.6497 14.0154L21.0868 13.491L13.6799 6.55154Z"
                  fill="#DF1E36"
                />
                <path
                  d="M8.17864 6.46599L0.947876 13.4261L1.39167 13.9442L8.62244 6.9841L8.17864 6.46599Z"
                  fill="#DF1E36"
                />
                <path
                  d="M10.8624 9.17982L0.792847 1.52634L1.17767 0.95755L10.8688 8.32353L20.8064 1.02023L21.1825 1.59549L10.8624 9.17982Z"
                  fill="#DF1E36"
                />
              </g>
              <defs>
                <clipPath id="clip0_3_178">
                  <rect width="22" height="15" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </a>

          <a href="tel:17576277373" rel="noreferrer" target="_blank">
            <svg
              width="16"
              height="26"
              viewBox="0 0 16 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6721 26H3.32819C2.4458 25.999 1.59984 25.649 0.975899 25.0268C0.351957 24.4046 0.000991289 23.5609 0 22.681V3.31903C0.000991289 2.43907 0.351957 1.59544 0.975899 0.973212C1.59984 0.350988 2.4458 0.000988559 3.32819 0H12.6721C13.5545 0.000988559 14.4004 0.350988 15.0244 0.973212C15.6483 1.59544 15.9993 2.43907 16.0003 3.31903V22.681C15.9993 23.5609 15.6483 24.4046 15.0244 25.0268C14.4004 25.649 13.5545 25.999 12.6721 26ZM3.32846 1.20132C2.76545 1.20195 2.22569 1.42527 1.82758 1.82228C1.42948 2.21929 1.20554 2.75757 1.2049 3.31903V22.681C1.20554 23.2424 1.42948 23.7807 1.82759 24.1776C2.22571 24.5746 2.76548 24.7979 3.32846 24.7984H12.6721C13.2351 24.7979 13.7748 24.5746 14.1729 24.1776C14.571 23.7807 14.795 23.2424 14.7956 22.681V3.31903C14.795 2.75757 14.5711 2.21929 14.173 1.82228C13.7748 1.42527 13.2351 1.20195 12.6721 1.20132H3.32846Z"
                fill="#DF1E36"
              />
              <path
                d="M12.9795 20.6289H3.02054C2.69047 20.6285 2.37404 20.4975 2.14065 20.2648C1.90725 20.032 1.77594 19.7165 1.77551 19.3873V4.73379C1.77601 4.30644 1.94646 3.89673 2.24948 3.59455C2.55249 3.29237 2.96333 3.12238 3.39186 3.12189H12.6081C13.0367 3.12238 13.4475 3.29237 13.7505 3.59455C14.0535 3.89673 14.224 4.30644 14.2245 4.73379V19.3873C14.224 19.7165 14.0927 20.032 13.8594 20.2648C13.626 20.4975 13.3095 20.6285 12.9795 20.6289ZM3.39186 3.843C3.155 3.84321 2.92789 3.93712 2.76038 4.10412C2.59287 4.27112 2.49863 4.49758 2.49835 4.73379V19.3873C2.49856 19.5253 2.55366 19.6576 2.65155 19.7552C2.74944 19.8528 2.88214 19.9077 3.02054 19.9078H12.9795C13.1179 19.9077 13.2505 19.8528 13.3484 19.7552C13.4463 19.6576 13.5014 19.5253 13.5016 19.3873V4.73379C13.5014 4.49758 13.4071 4.27112 13.2396 4.10412C13.0721 3.93712 12.845 3.84321 12.6081 3.843H3.39186Z"
                fill="#DF1E36"
              />
              <path
                d="M8.08973 24.0085C7.82945 24.0085 7.57502 23.9315 7.35861 23.7873C7.1422 23.6431 6.97354 23.4381 6.87395 23.1983C6.77436 22.9585 6.74831 22.6946 6.79911 22.4401C6.84991 22.1855 6.97526 21.9517 7.15933 21.7682C7.34339 21.5846 7.57789 21.4597 7.83317 21.4091C8.08845 21.3585 8.35305 21.3845 8.5935 21.4839C8.83395 21.5832 9.03945 21.7515 9.18401 21.9673C9.32857 22.1831 9.40571 22.4369 9.40565 22.6965C9.4053 23.0444 9.26654 23.3779 9.01983 23.6239C8.77311 23.8699 8.4386 24.0082 8.08973 24.0085ZM8.08973 21.8649C7.92482 21.8649 7.76363 21.9138 7.62654 22.0052C7.48944 22.0966 7.38261 22.2265 7.31954 22.3784C7.25647 22.5304 7.23999 22.6976 7.2722 22.8588C7.3044 23.0201 7.38384 23.1683 7.50047 23.2846C7.6171 23.4008 7.76568 23.48 7.92742 23.5121C8.08917 23.5441 8.25681 23.5276 8.40917 23.4647C8.56152 23.4017 8.69173 23.2952 8.78335 23.1584C8.87496 23.0217 8.92386 22.8609 8.92386 22.6965C8.92358 22.4759 8.83559 22.2646 8.67921 22.1087C8.52284 21.9528 8.31084 21.8651 8.08973 21.8649Z"
                fill="#DF1E36"
              />
              <path
                d="M10.0131 1.92432H6.0929V2.40479H10.0131V1.92432Z"
                fill="#DF1E36"
              />
            </svg>
          </a>
        </div>
      </header>

      <main>
        <div className="flex flex-col items-center mb-8 text-gray-dark px-1">
          <h1 className="mb-1 font-title text-center tracking-wide uppercase">
            Legal Marketing
          </h1>
          <p className="mb-1 text-center text-xl">
            We connect lawyers and clients.
          </p>
          <p className="mb-1 text-center text-xl">Case closed.</p>
          <p className="mb-2 font-title text-center tracking-wide uppercase">View Our Reel</p>

          <div
            style={{
              width: 720, 
              maxWidth: '100%',
              margin: "auto",
              border: "1px solid black",
              position: "relative",
            }}
          >

          
            <div
              style={{
                padding: "56.25% 0 0 0",
                position: "relative",
                width: "100%",
              }}
            >
              <iframe
                src="https://player.vimeo.com/video/691139955?h=48f767018f&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameborder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowfullscreen
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                }}
                title="Davis Reel for Zinda"
              ></iframe>
            </div>
            <script src="https://player.vimeo.com/api/player.js"></script>
          </div>

          {/* <button
            className="watch-our-reel border-2 border-red py-1/2 px-2 bg-white font-title text-red uppercase pointer"
            onClick={() => {
              openLightbox(VIDEOS_ALL[0]);
            }}
          >
            Watch Our Reel
          </button> */}
        </div>

        <VideoSection
          body="Martin & Jones asked Davis for help generating new business and market share in a crowded Raleigh-area personal injury market. We responded by repositioning the brand, developing a new tagline and producing updated video spots. Results? We won’t settle for anything less."
          className="bg-blue text-white"
          handleClick={openLightbox}
          heading="The Right Lawyer When It Matters Most"
          logo="/img/martin-jones.png"
          logoAltText="Martin & Jones"
          videos={VIDEOS.MARTIN_JONES}
        />

        <VideoSection
          body="Cooper Hurley Personal Injury Lawyers made a big investment in the 2022 Super Bowl, hoping to generate new business and increase share-of-voice in the Hampton Roads legal market. Our creative solution was an emotionally powerful television campaign that saw real clients telling genuine stories about finding help after a horrific accident."
          className="bg-yellow text-gray-dark"
          handleClick={openLightbox}
          heading="Real People. Real Stories."
          logo="/img/cooper-hurley.png"
          logoAltText="Cooper Hurley"
          videos={VIDEOS.COOPER_HURLEY}
        />

        <section className="flex flex-col items-center p-4 bg-red text-white">
          <p className="mb-1 font-title tracking-wide uppercase">
            Let's get started
          </p>
          <p className="mb-1 text-xl">
            The trusted partner every law firm needs.
          </p>
          <a
            className="contact-davis mb-1 border-1 border-white py-1/2 px-2 bg-red font-title no-underline text-white uppercase"
            href="mailto:inquiries@davisadagency.com"
            rel="noreferrer"
            target="_blank"
          >
            Contact Davis
          </a>
          <div
            alt="Davis Ad Agency"
            className="mb-2"
            style={{ width: 100, height: 100 }}
          >
            <svg
              class="logo"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 171.2 171.2"
            >
              <path fill="#df1e36" d="M0 0v171.2h171.2V0H0"></path>
              <path
                d="M18.8 135h9.3c7.9 0 12.3-4.1 12.3-12.8v-21.8c0-7.5-4.2-12-11.7-12h-9.9V135zm6.6-40.4h3.1c3.9 0 5.2 2.1 5.2 5.8v22.5c0 4-1.4 5.8-5.2 5.8h-3.1V94.6zm39.2 24h-6.7l3.3-16.9h.1l3.3 16.9zm-16.5 16.3h6.7l2-10h9.1l2 10h6.7L64.1 88.3h-5.6l-10.4 46.6zm55.1-46.6h-7.1l-5.4 32.5h-.2l-5.3-32.5h-7.1l9.5 46.6h5.9l9.7-46.6zm9.4 46.7h6.7V88.4h-6.7V135zm40-36.5A10.82 10.82 0 0 0 141.5 88c-7.7 0-11.5 5.7-11.5 12.8 0 5.8 1.5 9.6 7.3 12l4.8 2c3.3 1.3 3.9 2.1 3.9 7.1 0 4.2-.3 6.9-4.8 6.9-3.4 0-4.7-2.4-4.7-4.8v-2.1h-6.7v2.5a11.1 11.1 0 0 0 10.9 11.1h.5c8.6 0 11.5-5.8 11.5-13.7 0-6.8-1.1-10.5-7-12.8l-5.1-2c-3.5-1.4-3.8-2.6-3.8-6.2 0-3.1.9-6 4.5-6 3.4 0 4.7 2.5 4.7 5.6v1.5h6.7v-3.4zM60.6 141.5h2.1l4 17.9h-2.6l-.8-3.9h-3.5l-.8 3.9h-2.5zm1.1 5.1h-.1l-1.3 6.5h2.6zM69.1 141.5h3.8c2.9 0 4.5 1.7 4.5 4.6v8.4c0 3.3-1.7 4.9-4.7 4.9h-3.6v-17.9zm2.6 15.5h1.2c1.4 0 2-.7 2-2.2v-8.6c0-1.4-.5-2.2-2-2.2h-1.2zM88.6 141.5h2.1l4 17.9h-2.6l-.8-3.9h-3.5l-.8 3.9h-2.5zm1.1 5.1l-1.3 6.5H91zM97 145.7a4.3 4.3 0 0 1 8.6 0v.9H103v-.9a1.76 1.76 0 1 0-3.5-.3v9.8a1.76 1.76 0 1 0 3.5.3v-3.6h-2v-2.3h4.5v5.6a4.3 4.3 0 1 1-8.6 0l.1-9.5zM109 141.5h7.6v2.4h-5.1v5.3h4.4v2.4h-4.4v5.3h5.1v2.6H109v-18zM119.4 141.5h2.5l3.9 10.8v-10.8h2.6v17.9H126l-3.9-10.8v10.8h-2.6l-.1-17.9zM139.9 155.4a4.06 4.06 0 0 1-4.1 4.1 3.86 3.86 0 0 1-4.2-4.2v-9.9a4 4 0 0 1 3.9-4.1h.3a4.1 4.1 0 0 1 4.1 4v1.3h-2.6v-.9a1.77 1.77 0 0 0-1.5-1.9h-.1c-1.3 0-1.6.9-1.6 2.1v9.2c0 1 .4 1.8 1.6 1.8a1.56 1.56 0 0 0 1.6-1.5v-1.2h2.6v1.2zM145.5 151.8l-3.5-10.3h2.7l2.1 7.1 2.1-7.1h2.8l-3.5 10.3v7.6h-2.6l-.1-7.6z"
                fill="#fff"
              ></path>
            </svg>
          </div>
          <p>3200 Pacific Ave., Suite 100, Virginia Beach, VA 23451</p>
          <p style={{ marginTop: 10 }}>
            <a
              style={{ color: "#fff", textDecoration: "none" }}
              href="tel:17576277373"
              target="_blank"
              rel="noreferrer"
            >
              (757) 627-7373
            </a>
          </p>
        </section>
      </main>

      <section className={`lightbox absolute ${videoUrl ? "" : "hidden"}`}>
        <div
          className="lightbox-overlay w-full h-full pointer"
          onClick={closeLightbox}
        />
        <div className="absolute top-0 flex justify-center items-center w-full h-full pointer-events-none">
          {videoUrl && (
            <div className="flex flex-col">
              <p className="lightbox-title mb-1 font-title text-white uppercase">
                {videoTitle}
              </p>
              <iframe
                allow="autoplay; fullscreen;"
                allowFullScreen
                className="lightbox-iframe pointer-events-auto"
                frameBorder="0"
                src={videoUrl}
                title="Vimeo"
              />
            </div>
          )}
        </div>

        <div className="lightbox-arrows absolute top-0 flex justify-between items-center w-full h-full pointer-events-none">
          <span onClick={setVideoPrevious} style={{ zIndex: 100 }}>
            <i
              className="lightbox-arrow-left fa-thin fa-chevron-left absolute p-1 text-white pointer pointer-events-auto"
              onClick={setVideoPrevious}
            />
          </span>

          <span onClick={setVideoNext} style={{ zIndex: 100 }}>
            <i className="lightbox-arrow-right fa-thin fa-chevron-right absolute p-1 text-white pointer pointer-events-auto" />
          </span>
        </div>

        <span onClick={closeLightbox} style={{ zIndex: 100 }}>
          <i className="lightbox-close absolute fa-light fa-xmark text-white pointer pointer-events-auto"></i>
        </span>

        {/* <div className="lightbox-thumbnails absolute w-full pointer-events-none">
          <div className="flex pointer-events-auto">
            {VIDEOS_ALL.map((video, index) => (
              <img
                alt=""
                className="lightbox-thumbnail pointer"
                key={index}
                onClick={() => {
                  openLightbox(video);
                }}
                src={video.thumbnail}
              />
            ))}
          </div>
        </div> */}
      </section>
    </>
  );
};

export { Home };
