import { Home } from './components/Home';

import 'css/reset.css';
import 'css/library.css';

const App = () => {
  return (
    <Home />
  )
};

export default App;
