import 'css/library.css';

const VideoThumbnail = (props) => {
  const isLarge = props.isLarge || false;

  return (
    <div className="video-thumbnail">
      <div
      className={`${isLarge ? 'video-thumbnail-image-large ' : ''}video-thumbnail-image flex justify-center items-center mx-auto mb-1 bg-gray pointer`}
      onClick={() => {
        props.handleClick(props.video);
      }}
      style={{
        backgroundImage: `url(${props.video.thumbnail || ''})`
      }}
      >
        <img alt="Play Video" src={`${isLarge ? '/img/play-large.png' : '/img/play.png'}`} />
      </div>
      <div className="flex justify-end">
        <p className="font-title text-gray-dark uppercase">{ props.video.label }</p>
      </div>
    </div>
  )
};

export { VideoThumbnail };
