import { VideoThumbnail } from 'components/VideoThumbnail';

import 'css/library.css';

const VideoSection = (props) => {
  return (
    <section className="video-section mx-auto mb-8">
      <div className="video-section-heading flex flex-col justify-center items-center mb-1">
        <div className={`video-section-description py-4 px-1 md:px-4 ${ props.className}`}>
          <div className="mx-1">
            <img alt={ props.logoAltText } className="logo mb-2" src={ props.logo } />
            <h2 className="mb-1 text-xl">{ props.heading }</h2>
            <p className="leading-2">
              { props.body }
            </p>
          </div>
        </div>
        <div className="video-thumbnail-large-container relative">
          <VideoThumbnail
          handleClick={props.handleClick}
          isLarge={true}
          video={props.videos.featured}
          />
        </div>
      </div>

      <div className="px-1">
        <div className="video-thumbnails mx-auto">
          <div className="video-thumbnails-squeeze flex flex-wrap justify-center">
            {props.videos.grid.map((video, index) => (
              <VideoThumbnail
              handleClick={props.handleClick}
              key={index}
              video={ video }
              />
             ))}
          </div>
        </div>
      </div>
    </section>
  )
};

export { VideoSection };
